import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import TwoColumnGrid from '../components/TwoColumnGrid'
import HubspotForm from 'react-hubspot-form'
import GoogleMap from '../components/GoogleMap'
import mapbg from '../img/sign-here-2x.png'

export const ContactPageTemplate = ({
  title,
  metadescription,
  image,
  header,
  mainpitch,
  casestudy,
  helmet,
}) => (
    <div className="contact-pg content">
      {helmet || ''}
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top left`,
          justifyContent: 'left',
          height: '500px'
        }}
      >
        <div className="container">
          <div className="columns" style={{ alignItems:'center' }}>
            <div className="column">
              <div>
                <h1
                  className="has-text-weight-bold"
                  style={{
                    color: 'white',
                    lineHeight: '1',
                    padding: '0.25em',
                  }}
                >
                  {header}
                </h1>
              </div>
            </div>
            <div className="column">
                <section className="footer--form" id="footer-form">
                  <div className="hubspot-form">
                    <HubspotForm
                      portalId='1987452'
                      formId='12c0e16f-8a04-4d53-81f6-538e245f5131'
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </section>
            </div>
          </div>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12 hm-page">
              <div className="section" style={{paddingBottom:'20px'}}>
                <div className="content columns">
                  <div className="column is-7 is-offset-one-fifth" style={{
                    flexDirection: 'column',
                  }}>
                    <div className="tile has-text-centered">
                    <div className="casestudy__intro has-text-centered">
                      <h2 className="title" style={{
                        margin:'10px auto',
                      }}>{mainpitch.title}</h2>
                      </div>
                    </div>
                    <div className="tile has-text-centered">
                      <p className="subtitle">{mainpitch.description}</p>
                    </div>
                  </div>
                </div>
                <div className="columns casestudy-section">
                  <div className="column is-12">
                    <TwoColumnGrid gridItems={casestudy} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="has-text-centered">Visit Us In Sunny Orange County</h2>
              <div className="google-map" style={{width: '100%', height: '500px'}}>
                <GoogleMap />
                <img src={mapbg} className="mapbg" alt="map"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
)

ContactPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  metadescription: PropTypes.string,
  header: PropTypes.string,
  mainpitch: PropTypes.object,
  casestudy: PropTypes.array,
  helmet: PropTypes.object,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <ContactPageTemplate
        {...frontmatter}
        helmet={
          <Helmet titleTemplate="%s | Human">
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.metadescription}`} />
          </Helmet>
        }
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        metadescription
        header
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitch {
          title
          description
        }
        casestudy {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
          items {
            itemheader
            itemnumber
            itemcolor
          }
          linktitle
          linkurl
        }
      }
    }
  }
`
