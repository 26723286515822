import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 33.6339654,
      lng: -117.7335701
    },
    zoom: 13
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact        
          bootstrapURLKeys={{ key: 'AIzaSyBTuZBc60Tjtc8s6zpiJKiKu1h3cU1PZxQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={33.6339654}
            lng={-117.7335701}
            text="Human Marketing"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;